@import './config.scss';
@import '../assets/fonts/helvetica.css';


html {
    font-size: 16px;
}

h1 {
    color: var(--main);
    font-size: 22px;
    font-weight: 600;
    // line-height: 2.2rem;
    // @media (min-width: 2560px) {
    // font-size: 1.6444vw;
    // line-height: 1.6444vw;
    // }
}

p {
    font-size: 16px;
    // line-height: 20px;
    font-family: 'Helvetica Neue', sans-serif;
    // @media (min-width: 2560px) {
    // font-size: 0.9556vw;
    // line-height: 1.4222vw;
    // }
}

body {
    font-family: 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

label {
    color: var(--main);
    font-size: 14px;
    font-weight: 400;
    // line-height: 1.7rem;
    white-space: normal !important;
    margin-bottom: 1rem;
}

.header21 {
    font-weight: 500;
    font-size: 21px;
    margin-bottom: 30px;
}

.header36 {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    @media (max-width: $smMax) {
        font-size: 24px;
    }
}

.title21 {
    font-weight: 300;
    font-size: 21px;
    line-height: 25px;
}