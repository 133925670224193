@import "~react-redux-toastr/src/styles/index";
@import "./styles/config.scss";

body {
  overflow-y: auto;
  height: 100%;

  &:not(.visualisationPage) {
    @media (max-width: $smMax) {
      .nonResultsPage {
        & ~ .main {
          padding-top: calc(1.5 * 124px) !important;
          margin-top: 0;
        }
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.overflowHidden {
  overflow: hidden !important;
}

#root,
html {
  color: var(--main);
  background-color: #fff;
  height: 100%;
  min-height: -webkit-fill-available;
}

.fakeFocusable {
  display: block;
  position: relative;
  height: 0;
  color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: transparent;
  }
}

.routeContainer {
  width: 100%;
  position: relative;
  height: 100%;
  height: -webkit-fill-available;

  &:before {
    z-index: 13;
    width: 100%;
    content: "";
    display: block;
    position: fixed;
    height: 4px;
    background: linear-gradient(90deg, #ffd100 0%, #e63323 51.84%, #9e1515 100%);
  }

  & > div {
    height: 100%;
    height: -webkit-fill-available;
  }

  & > .container-fluid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
  }

  .animationMask,
  .animationMaskInPage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
  }

  .pageContainer {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;

    .main {
      padding-top: 0px;
      padding-bottom: 40px;
      overflow: hidden;
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      margin: 40px 40px 0;
      min-height: 100vh;

      &.main_expert {
        padding-left: 450px;

        @media (max-width: $smMax) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      
      @media (max-width: $smMax) {
        padding: var(--mobileHeaderHeight) 20px;
        margin-left: 0;
        margin: 0;
        background-color: white;
      }

      .mainBg {
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 24px;
        flex: 1 1 100%;
        flex-direction: row;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 64px;

        @media (max-width: $smMax) {
          background-color: rgba(0, 0, 0, 0.02);
          padding: 22px 20px 22px 20px;
        }
      }
    }
  }
}

.basicButton {
  color: var(--gray-light);
}

.customInput,
textarea {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 13px 16px;
  width: 100%;
}

.dropdownInput {
  margin-top: 0;
  width: 100%;
  cursor: pointer;

  div[class*="-control"] {
    border: 1px solid #eeeeee;
    border-radius: 8px;
  }

  span[class*="-indicatorSeparator"] {
    display: none;
  }

  div[class*="IndicatorsContainer"] {
    right: 20px;
    position: relative;
  }

  div[class*="ValueContainer"] {
    padding: 6px 12px;
  }

  div[class*="singleValue"] {
    max-width: calc(100% - 35px);
  }
}

.contentContainer > .container-fluid {
  overflow: auto;
}

.overflowHiddenOff {
  overflow: initial !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dropdown input.textMaterial {
  cursor: pointer;
}

.arrow {
  &.left {
    transform: rotate(180deg);
  }
}

button {
  &:focus,
  &:active {
    outline: none;
  }
}

a,
button {
  outline: none !important;

  .toggle {
    transition: fill 0.2s linear;
    transition: stroke 0.2s linear;
  }

  p {
    transition: color 0.2s linear;
    margin: 0;
  }

  &.filled {
    &:hover {
      .toggle {
        transition: fill 0.2s linear;
        transition: stroke 0.2s linear;
        fill: var(--green-light);
      }
    }
  }

  &:hover {
    text-decoration: none !important;

    p {
      color: var(--green-light);
    }

    .iconWrapper.filled {
      .toggle {
        transition: fill 0.2s linear;
        transition: stroke 0.2s linear;
        fill: var(--green-light);
        stroke: transparent;
      }
    }
  }
}

.strokeToggle {
  transition: stroke ease-in-out 0.3s;
  vector-effect: non-scaling-stroke;
}

.fillToggle {
  transition: fill ease-in-out 0.3s;
}

.crossFade {
  opacity: 0;
}

.basicLink:hover {
  svg .toggle {
    stroke: var(--gray) !important;
  }
}

.iconClickable {
  cursor: pointer;

  &:hover {
    .strokeToggle {
      stroke: var(--main) !important;
    }

    .fillToggle {
      fill: var(--main) !important;
    }
  }

  &:active,
  &:focus {
    border: none;
    outline: none;

    .strokeToggle {
      stroke: var(--blue-light) !important;
    }

    .fillToggle {
      fill: var(--blue-light) !important;
    }
  }
}

::placeholder {
  color: var(--gray);
  font-weight: 500 !important;
}

:-ms-input-placeholder {
  color: var(--gray);
  /* Internet Explorer 10-11 */
  font-weight: 500 !important;
}

::-ms-input-placeholder {
  color: var(--gray);
  /* Microsoft Edge */
  font-weight: 500 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input,
textarea {
  border-radius: 0;

  &:focus {
    outline: none;
  }
}

.invalid input {
  border-bottom: 2px solid var(--red) !important;
  color: var(--red) !important;
}

// Tooltips
.rc-tooltip {
  opacity: 1;
  animation: opacityAnime 0.3s;
  animation-timing-function: ease-in-out;

  @keyframes opacityAnime {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.rc-tooltip-hidden {
    transition: opacity ease-in-out 0.3s;
    opacity: 0;
    display: block !important;
  }

  &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 7px !important;
  }

  .rc-tooltip-arrow {
    border-top-color: #263238;
  }

  .rc-tooltip-inner {
    padding: 16px;
    border-radius: 8px !important;
    background-color: #263238 !important;
    box-shadow: 0 27px 100px -20px rgba(38, 50, 56, 0.2), 0 4px 15px -10px rgba(38, 50, 56, 0.2),
      0 9px 30px -15px rgba(38, 50, 56, 0.2), 0 18px 60px -30px rgba(38, 50, 56, 0.2) !important;
    max-width: 284px;

    p {
      &.blockTitle {
        font-weight: 500;
        margin-top: 4px;
      }

      &.blockValue {
        margin-top: -1px;
        margin-bottom: 5px;
        font-weight: 300;
      }

      font-weight: 500;
      font-size: 12px !important;
      margin-bottom: 0;
      line-height: 19px;
      text-align: left;
    }
  }
}

.stagger {
  opacity: 0;
}

.labelChecked {
  opacity: 1 !important;
  transform: scale(1.25);
  z-index: 2;
  @media (max-width: $smMax) {
    transform: scale(1.05) !important;
    box-shadow: 0px 12px 64px 5px rgba(0, 0, 0, 0.12);
  }

  .checkmark {
    transform: scale(0.83);
    content: "";
    display: block;
    background-color: var(--main);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
}

.cards {
  input:checked + label,
  input:focus + label,
  input:active + label {
    @extend .labelChecked;
  }

  label:hover {
    z-index: 2 !important;
    background-color: #fff;
  }
}

.card {
  background-color: #ffffff;
  border-radius: 24px;
  padding: 70px 48px;
  text-align: center;
  min-width: 14vw;
  min-height: 14vw;
  margin: 30px 30px;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;

  &.elasticHeight {
    max-height: none;
    min-height: auto;
  }

  &.ghost {
    visibility: hidden;
  }

  &.small {
    padding: 20px 24px;
    min-height: 54px;
    margin: 10px;

    .checkmark {
      top: 0;
      bottom: 0;
      margin: auto;
      width: 32px;
      height: 32px;
    }

    p {
      font-weight: 500;
      text-align: left;
    }
  }

  .checkmark {
    position: absolute;
    opacity: 0;
    width: 40px;
    height: 40px;
    right: var(--padding-4);
    top: var(--padding-4);
    transition: opacity 0.3s ease-in-out;
    border-radius: 50%;

    @media (max-width: $smMax) {
      width: 32px;
      height: 32px;
    }

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
    }
  }

  @media (max-width: $smMax) {
    .country-label {
    }
  }
}

.card:not(.nonShadow) {
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.07);
}

.cards {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;

  .country-image {
    width: 62px;
    height: 44px;
  }

  @media (max-width: $smMax) {
    flex-direction: column;

    .card {
      margin: 8px 0 !important;
      flex-direction: row;
      padding: 24px;
      justify-content: space-between;
      align-items: center;

      .flagIcon {
        margin: 0;
      }

      .country-image {
        width: 48px;
        height: 34px;
      }

      .checkmark {
        top: 0;
        bottom: 0;
        right: 20px;
        margin: auto;
      }
    }

    & + button {
      width: 100%;
    }
  }

  input {
    position: absolute;
    opacity: 0;
  }

  label {
    p {
      margin: 0;
      font-weight: 500;
    }
  }

  .subCard {
    @extend .card;
  }

  &.hasActiveChild {
    .card {
      opacity: 0.5;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
        transform: scale(1.25);
      }

      &:not(.nonShadow) {
        &:hover,
        &:active,
        &:focus,
        &.active {
          /*@extend .labelChecked;
                    Remove checkbox on hover
                    */
        }
      }
    }
  }
}

body.ReactModal__Body--open {
  // there is a scroll jump after modal close on mobile
  // overflow: hidden !important;
  // position: fixed;
  // width: 100%;
  // height: 100%;
}

.ReactModal__Body--open {
  .ReactModalPortal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
  }
}

.ReactModal__Overlay {
  transition: all 500ms ease-in-out;
  overflow: auto;
  background-color: rgba(134, 128, 128, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  display: flex;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 9999;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.large-modal {
  box-shadow: 0px 24px 80px 8px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  margin: auto;
  background-color: white;
  overflow: hidden;
  z-index: 10;
  flex: 0 1 600px;
  display: flex;
  flex-direction: column;
  min-height: 670px;
  max-height: 90vh;

  &.large-modal-half {
    min-height: 370px;
  }

  @media (max-width: $smMax) {
    min-height: calc(100vh - 60px);
    max-width: calc(100vw - 60px);
  }
}