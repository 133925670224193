@import '../../styles/config.scss';

.backBtn {
  position: absolute;
  left: 16%;
  top: 72px;

  @media (max-width: $smMax) {
    //display: none;
    left: 24px;
    top: 30px;

    svg {
      height: 14px;
    }
  }
}