@import '../../styles/config.scss';

.thicknessContainer {

    .thicknessCard {
        flex: 0 1 152px;

        @media (max-width: $smMax) {
            flex: 1 1 50%;
        }
    }

    .cards {
        margin-right: auto;
        margin-left: auto;
        max-width: 350px;

        @media (max-width: $smMax) {
            flex-direction: row;
        }


        .card {
            text-align: left;
            align-items: flex-start;
            font-weight: 500;
            max-width: none;
            min-width: auto;
        }
    }
    .thicknessMessage {
        background: #FFFFFF;
        border-radius: 24px;
        max-width: 31rem;
        padding: var(--padding-4) 2rem 2rem;

        p:last-child { margin: 0; }
    }
}