/* Utilities */
.bgGray {
    background-color: rgba(0, 0, 0, 0.02);
}

.bgGreenLight {
    background-color: var(--green-bg);
}

.bgBlueLight {
    background-color: var(--blue-bg);
}

.bgRedLight {
    background-color: var(--red-light);
}

.bgGreen {
    background-color: var(--green);
}

.colorGreyLight {
    color: var(--gray-light);
}

.bgGreyLight {
    background: var(--gray-light);
}

.colorGrey {
    color: var(--gray);
}

.colorBlue {
    color: var(--blue);
}

.colorBlueLight {
    color: var(--blue-light);
}

.colorOrange {
    color: var(--orange);
}

.colorMain {
    color: var(--main);
}

.colorGreen {
    color: var(--green);
}

.colorRed {
    color: var(--red);
}

.colorBlack {
    color: var(--dark);
}

.colorWhite {
    color: var(--light);
}

.colorGrayDark {
    color: var(--gray-text-dark);
}

.font-24 {
    font-size: 24px;
}

.font-16 {
    font-size: 16px;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.pointer {
    cursor: pointer;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-300 {
    font-weight: 300 !important;
}

.lh25 {
    line-height: 2.5;
    ;
}

.iconWrapper {
    display: inline-block;
    width: 18px;
    height: 18px;

    &.large {
        width: 24px;
        height: 24px;
        line-height: 24px;
    }

    &.small {
        width: 14px;
        height: 14px;
        line-height: 14px;
    }

    img,
    svg {
        max-width: 100%;
        width: auto;
        height: 100%;
    }



    &.filled {
        .toggle {
            transition: fill .2s linear, stroke .2s linear;
        }

        &:hover {
            .toggle {
                fill: var(--green-light);
                stroke: transparent;
            }
        }


    }

    &:hover,
    &.active {
        .toggle {
            stroke: var(--green-light);
        }
    }
}

.p-relative {
    position: relative;
}

.strokeWhite {
    .toggle {
        stroke: white;
    }
}

.strokeGrey {
    .toggle {
        stroke: var(--gray);
    }
}

.fillWhite {
    .toggle {
        fill: white;
    }
}

.nonButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.mt-40 {
    margin-top: 40px;
}

.toggle {
    transition: fill .2s linear;
    transition: stroke .2s linear;
}
