.loading {
    width: 100vw;
    height: 100vw;
    //background-color: rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    top: 0px;
    z-index: 1000;

    &.relative {
        right: 0;
        top: 0;
        bottom: 0;
        width: auto;
        height: auto;
        background-color: transparent;
        position: relative;
        margin: auto;

        .wrapper {
            top: calc(50% - 50px);
        }
        .logo {
            @media (max-width:768px) {
                height: 40px;
                margin: 32px;
            }
        }
    }

    &.loadMore {
        top: unset;
        bottom: 0;
    }

    p {
        position: relative;
        top: calc(50vh - 50px);
        text-align: center;
        color: white;
        font-size: 2.4rem!important;
        white-space: pre;
    }

    .loader {
        svg {
            height: 100px;
            width: 100px;
            margin: auto;
            display: block;
            animation-name: spin;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            fill: #A51941;
            // fill: rgba(2, 110, 185, 0.42);
            //fill: rgba(38, 50, 56, 0.42); //main
        }
    }

    .logo {
        position: absolute;
        left: 0;
        right: 0;
        // top: 9px;
        margin: auto;

        top: 0;
        bottom: 0;
        // width: 12px;
        height: 28px;
        @media (max-width: 600px) {  
            height: 40px;
            margin: 2px 0 3px 0;
        }

        svg, img {
            width: 100%;
            height: 100%;
            // fill: rgba(2, 110, 185, 0.42);
            // fill: rgba(2, 110, 185, 1);
            g,path {
                fill: #e60000;
            }
        }
    }

    .wrapper {
        position: relative;
        position: relative;
        top: calc(50vh - 100px);
    }

}

button>.loading {
    background-color: transparent;
    width: unset;
    height: 100%;
    position: absolute;
    right: 4px;
    top: 13px;
    left: initial;
    //margin-top: -9px;
    //margin-left: -3px;


    .wrapper {
        position: unset;
        top: unset;
        height: 100%;

        svg {
            height: 18px;
            fill: var(--main);
            width: unset;
            @media (max-width:768px) {
                // height: 30px;
            }
            @media (max-width:600px) {
                // height: 44px;
            }
        }

    }

}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}