@import './typography.scss';
@import './bootstrap-reboot.css';
@import './config.scss';
@import './bootstrap.css';
@import './utilities.scss';

.__react_component_tooltip {
  white-space: pre-wrap !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}