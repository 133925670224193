@import '../../styles/config.scss';

.standortContainer {
    .cards {
        fieldset {
            &:last-child {
                margin-right: 0;
            }
        }

        .card {
            height: 238px;
            width: 238px;
            padding-top: 65px;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            margin-right: 30px;
            margin-left: 0;

            @media (max-width: $smMax) {
                margin-left: auto !important;
                margin-right: auto !important;
                height: 80px;
                padding-top: 0;
                //min-width: 100%;
                max-width: 295px;
                width: 100%;
                flex-direction: row;
                justify-content: flex-start;
            }

            .country-label {
                margin-top: auto;
                margin-bottom: 48px;
                font-weight: 500;
                @media (max-width: $smMax) {
                    margin-top: 0;
                    margin-bottom: 0;
                    flex-grow: 1;
                    text-align: center;
                    font-size: 18px
                }
            }

            .checkmark {
                //account size for scaling up
                max-width: 40px;
                max-height: 40px;
                right: 15px;
                top: 15px;

                @media (max-width: $smMax) {
                    position: static;
                }
            }
        }
    }

    .flagIcon {
        //margin: var(--padding-2);
    }
}


.flexForm {
    flex-wrap: wrap;
    flex: 1 1 auto;
    display: flex;
    align-content: space-between;
    justify-content: center;
    width: 100%;

    @media (max-width: $smMax) {
        &+button, button {
            width: 100%;
        }
    }
}



