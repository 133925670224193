@import '../../styles/config.scss';

.concreteTypePage {
  .cards {
    //margin-bottom: 70px;
    margin-top: 60px;
    flex-direction: column;

    .card {
      align-items: flex-start;

      @media (max-width: $smMax) {
        margin: 0;
      }
    }
  }

  .cards.subCards {
    margin-top: 80px;
  }

  .arrowRight {
    &.active {
      path {
        stroke: var(--main);
      }
    }


    @media (max-width: $smMax) {
      transform: rotate(90deg);
    }
  }

  .simpleInputs {
    margin-top: 10px;
    text-align: left;


    input[type='radio'] {
      display: none;
    }

    label::before {
      position: absolute;
      left: 0;
      top: 5px;
      cursor: pointer;
      content: '';
      border: 1px solid #EEEEEE;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      outline: 1px solid var(--main);
    }

    input:checked + label::before {
      // box-shadow: 0px 0px 1px #000000 !important;
      border: 4px solid #fff !important;
      background-color: #000;
    }

    label {
      padding-left: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      cursor: pointer;
      span p {
        color: var(--gray-light);
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
      }
    }
  }

  .otherCementType {
    flex-basis: 100%;
    max-width: 324px;
    .basicLink { margin-top: 20px; }
    @media (max-width: $smMax) {
      flex-wrap: wrap;
      .title21 { min-width: 100%; }
      .basicLink { margin-top: 0 }
    }
  }
}
