:root {
  --main: #000000;
  --main-light: rgba(0, 0, 0, 0.1);

  --button-hover-bg: rgba(64, 64, 64, 1);
  --button-focus-bg: rgba(32, 32, 32, 1);

  --green-bg: rgba(100, 167, 11, 0.1);
  --green: #64a70b;

  --blue-bg: rgba(35, 97, 146, 0.1);
  --blue: #236192;

  --gray: #666666;
  --gray-light: #b3b3b3;
  // --gray-light2: #EEEEEE;
  --gray-lighter: #d9d9d9;
  // --gray-lighter: #EEEEEE;

  --gray-text: #b3b3b3;
  --gray-text-dark: #757575;
  // --gray-dark: #757575;
  --gray-bg: #fafafa;
  --gray-bg2: rgba(238, 238, 238, 1); // #eeeeee

  --gray-dark: #606060;

  --red: #e63323;
  --red-light: rgba(230, 51, 35, 0.1);

  --padding40: 40px;
  --padding-1: 4px;
  --padding-2: 8px;
  --padding-3: 16px;
  --padding-4: 24px;
  --padding-45: 32px;
  --padding-5: 48px;

  --mobileHeaderHeight: 82px;
}

$xsMin: 897px;
$xsMax: 898px;

$smMin: 897px;
$smMax: 898px;

$mdMin: 1024px;
$lgMin: 992px;
$xlMin: 1200px;
