@import '../../styles/config.scss';

.faqContainer {
  min-width: 668px;
  width: 668px;

  @media (max-width: $xlMin) {
    width: 100%;
    min-width: 100%
  }
}

.faqTitle {
  font-family: HelveticaNeue, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 40px 0 24px;
  text-align: left;
}

.faqSection {
  background: #FFFFFF;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  text-align: left;
  margin: 0 0 8px;
  cursor: pointer;
}

.faqSectionTitle {
  display: flex;
  align-items: center;
  padding: 1rem 20px;
  min-height: 52px;
  min-height: 52px;
  font-family: HelveticaNeue, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    background-image: url(./arrow.svg);
    position: absolute;
    right: 20px;
    top: 0;
    bottom:0;
    width: 12px;
    background-size: 12px 20px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    transition: transform ease .25s;

    .faqSectionExpanded & {
      transform: rotate(180deg);
    }
  }
  
  .faqSectionExpanded & {
    border-bottom: 1px solid #EEEEEE;
  }
}

.faqSectionContent {
  padding: 0 20px;
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  
  .faqSectionExpanded & {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
  }
}

.faqSectionText {
  line-height: 20px;
  color: #606060;
  margin: 0;
  padding: 16px 0;
  font-family: HelveticaNeue, sans-serif;
  font-size: 14px;
  font-weight: 300;
}