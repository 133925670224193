@import '../../styles/config.scss';

.language-switcher-container {
  position: absolute;
  bottom: 40px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575 !important;

  &.language-switcher-container_expert {
    position: static;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: -40px;
    padding-left: 40px;
    background-color: #fff;
  } 

  .language-switcher-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    min-width: 140px;
  }

  .dropdownInput div[class*="-control"] {
    border: none !important;
    background: none;
  }

  svg {
    display: inline;
  }

  div[class*="-control"]:hover {
    border: none !important;
    box-shadow: none !important;
  }

  &.desktop-language-switcher-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  
    @media (max-width: $smMax) {
      display: none;
    }
  }
  
  &.mobile-language-switcher-container {
    display: none;
  
    @media (max-width: $smMax) {
      display: none; //TODO temporarily hide switcher on mobile UI
      bottom: 0;
      height: 70px;
      width: 100%;
      padding: 0;
  
      .mobile-wrapper {
        padding-top: 70px;
        width: 180px;
        margin: 0 auto;
        display: flex;
        align-content: center;
        align-items: center;
      }
    }
  }
}

.language-switcher-wrap {
  display: flex;
  align-items: center;
  min-width: 100%;
}

.language-switcher-separator {
  color: #757575;
  padding-right: 24px;
  padding-left: 24px;
}

.language-switcher-faq {
  color: #757575;
  font-size: 16px;
}

.manual-link {
  flex-grow: 1;
  span {
    cursor: pointer;
  }
}