@import "../../styles/config.scss";

.bauteilContainer {
  form {
    max-width: 600px;
  }

  .cards {
    margin-bottom: 70px;
    margin-top: 60px;

    .card-parent {
      &.card-parent-3 .card,
      &.card-parent-4 .card {
        z-index: 1;
      }
    }

    .card {
      background: #ffffff;
      position: relative;
      box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.08);
      align-items: flex-start;
      max-width: 14.78rem;
      min-width: 14.78rem;

      @media (max-width: $smMax) {
        margin-left: auto !important;
        margin-right: auto !important;
        min-width: 0;
        max-width: 295px;
      }

      .checkmark {
        right: calc(24px / 1.26);
      }
    }
  }

  .subView {
    max-width: 90%;
    margin: auto;
    @media (max-width: $smMax) {
      max-width: 100%;
    }

    .pill {
      padding: 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      width: 4.625rem;
      font-size: 21px;
    }

    .subViewColumn {
      padding-left: 20px !important;
    }
  }

  .subViewTitle {
    height: 3rem;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &.subViewTitleColumn {
      flex-direction: column;
    }

    .question-icon {
      position: relative;
      cursor: pointer;
      margin-left: 10px;
    }

    .question-tooltip {
      width: 250px;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
      text-align: left;
      padding: 10px;
    }
  }

  .xdSelector {
    border-radius: 24px;
    display: flex;
    align-items: center;
    height: 2rem;
    justify-content: center;
    margin-right: 8px;
    background-color: var(--gray-lighter);
    color: var(--gray-dark);
    min-width: 70px;
    cursor: pointer;

    &.xdSelectorActive {
      background-color: var(--main);
      color: #fff;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .title21 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: $smMax) {
      text-align: center;
    }
  }

  .simpleInputs {
    margin-top: 10px;
    padding-left: 0;

    input[type="radio"] {
      display: none;
    }

    label.simpleInputsNoCheckbox {
      padding-left: 0;
      &::before {
        display: none;
      }
    }

    label::before {
      position: absolute;
      left: 0;
      top: 5px;
      content: "";
      outline: 1px solid var(--main);
      border: 4px solid #fff;
      width: 16px;
      height: 16px;
      border-radius: 8px;
    }

    input:checked + label::before {
      background-color: #000;
    }

    label {
      padding-left: var(--padding-4);

      p {
        margin-bottom: var(--padding-1);
      }

      span p {
        color: var(--gray-dark);
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
        white-space: pre-line;
      }
    }
  }
}
