@import '../../styles/config.scss';

#root {
  .sidebar {
    padding: var(--padding40) 0 var(--padding40) var(--padding40);
    transition: opacity 0.3s ease-in-out;
    position: relative;
    width: 400px;

    &.sidebar_expert {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 450px;
      height: 100vh;
      overflow: hidden;
      min-width: 450px;
      padding-bottom: 0;
      padding-left: 0;

      @media (max-width: $smMax) {
        min-width: 100%;
        width: 100%;
        height: 100%;
      }

      .simplebar-content {
        padding-left: 40px !important;
        
        @media (max-width: $smMax) {
          padding-left: 8px !important;
        }
      }
    }

    @media (max-width: $smMax) {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: -1;
      opacity: 0;
      overflow: auto;
      max-height: 100vh;

      &.nonResultsPage {
        opacity: 1;
        z-index: 10;
        display: flex;
        height: 180px;
        position: fixed;
        background-color: white;

        .header21 {
          display: none;
        }

        .selectList {
          position: fixed;
          left: 0;
          right: 0;
          top: 60px;
          background-color: transparent;
          display: flex;
          margin-left: 0;
          border-radius: 0;
          box-shadow: none;
          margin-top: 0;
          justify-content: space-between;
          max-width: 400px;
          margin: 0 auto;

          .pill {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: var(--padding-3) var(--padding-2);


            &.active {
              background-color: transparent;

              .stepTitle {
                background-color: rgba(0, 0, 0, 0.02);
              }
            }

            .stepTitle {
              padding: 4px 0;
              border-radius: 28px;
              font-size: 12px;
              margin-left: 0;
            }
          }
        }

        .settingsTitle, hr, .closeSettings {
          display: none !important;
        }
      }

      .selectList {
        width: 100% !important;
        margin-left: calc(-1 * 40px);
      }

      .closeSettings {
        position: absolute;
        right: 20px;
        top: 50px;
        margin: 0;

        @media (max-width: $smMax) {
          top: 36px;
        }
      }
    }

    .selectList {
      background-color: #fff;
      box-shadow: 0px 24px 80px 8px rgba(0, 0, 0, 0.07);
      border-radius: 0 24px 24px 0;
      margin-left: calc(40px * -1);
      padding: var(--padding40);
      padding-bottom: 70px;
      margin-top: 70px;

      @media (max-width: $smMax) {
        padding: 40px 20px;
      }

      .selectTitle {
        margin: 36px 0 12px;
      }

      .inputTitle {
        margin: 12px 0 2px;
        font-size: 14px;
      }

      &.selectList_expert .inputTitle { font-size: 13px }

      .inputWrap {
        margin: 12px 0 2px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .inputTitle { margin-top: 0 }

        svg {
          width: 16px;
          height: 16px;
          margin-left: 8px;
        }
      }
    }

    .flag {
      position: absolute;
      right: 0;
      top: 0;
      width: 23px;

      svg {
        width: 100%;
        height: auto;
      }
    }


    .icon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .main {
      overflow: hidden;
      height: 100vh;
    }

    .pdfChartWrapper {}

    .resetBtn {
      &:disabled {
        cursor: default;
      }
    }
  }

  .logo {
    max-width: 120px;
  }

  &.mobileHide {
    .sidebar {
      opacity: 0 !important;
    }
  }

  &.mobileSidebar {
    overflow: hidden;

    .sidebar {
      background-color: #fff;
      z-index: 30;
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
      margin: 0;
      padding-left: 20px;
      padding-top: 0;

      .selectList {
        box-shadow: none;
        margin-top: 0;
        padding: 0 24px;
        margin-left: 0;

        @media (max-width: $smMax) {
          padding-left: 0;
        }

        .title-21,
        hr {
          display: none;
        }

        .selectTitle {
          margin: 36px 0 12px;
        }
      }
    }
  }
}

.expert-tooltip {
  max-width: 250px;
}

.bauwerkParams {
  color: var(--gray-text-dark);
  font-size: 14px;
}

.mobileHeader {
  @media (max-width: $smMax) {
    position: fixed;
    top: 0;
    padding: 18px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07);
    width: 100vw;
    z-index: 11;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    img {
      height: 46px;
    }

    .header21 {
      font-size: 16px;
      margin-right: 1.25rem;
    }
  }

  @media (min-width: $smMin) {
    display: none;
  }
}

.stepTitle {
  font-size: 16px;
  font-weight: 300;
  color: var(--gray-light);
  margin-left: 12px;
  display: inline-block;
  margin-bottom: 0;
}

hr {
  border: 1px solid var(--gray-bg2);
  border-top: 1px solid transparent;
  width: 100%;
  left: 0;
  right: 0;
}

.pill {
  border-radius: 28px;
  padding: var(--padding-3) var(--padding-4);
  display: block;

  &.thickness {
    color: var(--main);
    background-color: var(--gray-bg);
    flex: 0 1 32%;
    padding: 8px 16px;
    text-align: center;
    margin-bottom: 10px;

    &:hover{
      background-color: var(--main);
      color: #fff;
      cursor: pointer;
    }

    &.selected {
      background-color: var(--main);
      color: #fff;
    }
  }

  &.completed {
    .stepTitle {
      font-weight: 400;
      color: var(--main);
    }

    .stepPill {
      background-color: var(--main);
    }

    svg {
      height: 6px;
    }
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.02);

    .stepTitle {
      font-weight: 500;
      color: var(--main);
    }

    .stepPill {
      background-color: var(--main);
      color: #fff;
    }
  }
}

.stepPill {
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  width: 24px;
  height: 24px;
  background-color: var(--gray-lighter);
  color: var(--gray-dark);
  display: inline-block;
  border-radius: 50%;
  bottom: 2px;
  position: relative;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  padding-bottom: 10px;
  background-color: #fff;

  &:before {
    position: absolute;
    top: -40px;
    left: -40px;
    right: -40px;
    height: 40px;
    content: "";
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent);
  }

  &.sticky-footer_no-shadow:before {
    display: none;
  }
}