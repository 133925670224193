.modalContainer {
    padding: 36px 36px 36px 36px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;

    .colContainer {
        margin-top: 32px;
        width: calc(100% + 20px);
    }

    .inputCol {
        padding-right: 20px !important;
        text-align: left;
    }

    .closeIcon {
        position: absolute;
        right: 44px;
        top: -32px;
    }
}