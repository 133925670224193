@import '../../styles/config.scss';

svg {
  overflow: visible !important;

  .xLabel,
  .yLabel {
    font-weight: 300;
    font-size: 14px;
    fill: var(--gray-text-dark);
  }

  .tick:nth-of-type(odd) {
    rect {
      fill: rgba(0, 0, 0, 0.01);
    }
  }

  .tick:nth-of-type(even) {
    rect {
      fill: rgba(0, 0, 0, 0.03);
    }
  }
}

.chartsContainer {
  max-width: calc(100% - 60px);
  margin: auto;
  margin-bottom: 53px;

  @media (max-width: $smMax) {
    max-width: calc(100% - 40px);
  }
}

.lineChart--svg {
  overflow: visible;
}

.chart--headline,
.chart--subHeadline {
  text-align: center;
}

.chart--headline {
  position: relative;
  font-weight: 100;
  font-size: 28px;
}

.chart--headline:before {
  position: absolute;
  content: "";
  bottom: 133%;
  left: 50%;
  width: 25%;
  margin: 0 0 0 -12.5%;
  border-top: 1px dashed #999999;
}

.chart--subHeadline {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
}

.charts--headline {
  text-align: center;
  color: #444;
  background-color: #fff;
  padding: 1em;
}

.lineChart--area {
  fill: transparent;
  transform: translateY(1px);
}

.lineChart--areaLine {
  fill: none;
  stroke: #E63323;
  stroke-width: 3;
  opacity: 0.8;
}

.lineChart--areaLine2 {
  stroke: #666666;
}

.lineChart--areaLine3 {
  stroke: #41B6E6;
  stroke-width: 0.5px;
  stroke-dasharray: 5;
}

.lineChart--bubble--label {
  fill: #fff;
  stroke: #fff;
  font-size: 12px;
}

.lineChart--bubble--value {
  fill: #fff;
  stroke: #fff;
  font-size: 12px;
  font-weight: bold;
}

.lineChart--circle {
  fill: #242f38;
}

.lineChart--circle__highlighted {
  fill: #242f38;
}

.lineChart--gradientBackgroundArea--top {
  stop-color: #6bb5ce;
  stop-opacity: 0.8;
}

.lineChart--gradientBackgroundArea--bottom {
  stop-color: #b0d7e5;
  stop-opacity: 0.8;
}

.lineChart--xAxisTicks .domain,
.lineChart--xAxis .domain,
.lineChart--yAxisTicks .domain {
  display: none;
}

.lineChart--xAxisTicks {
  .tick:last-of-type rect {
    fill: transparent !important;
  }

  .tick line {
    display: none;
  }
}

.lineChart--xAxis .tick:first-child,
.lineChart--yAxis .tick:first-child {
  display: none;
}

/**
   * Helper classes
   */
.hidden {
  display: none;
}
