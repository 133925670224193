@font-face {
  font-family: 'HelveticaNeue';
  font-style:  normal;
  font-weight: 300;
  src: url("HelveticaNeue-Light.woff") format("woff2"),
       url("HelveticaNeue-Light.woff") format("woff");
}

/* @font-face {
  font-family: 'HelveticaNeue';
  font-style:  italic;
  font-weight: 300;
  src: url("HelveticaNeue-LightItalic.woff2") format("woff2"),
       url("HelveticaNeue-LightItalic.woff") format("woff");
}  */

@font-face {
  font-family: 'HelveticaNeue';
  font-style:  normal;
  font-weight: 400;
  src: url("HelveticaNeue.woff2") format("woff2"),
       url("HelveticaNeue.woff") format("woff");
}

/* @font-face {
  font-family: 'HelveticaNeue';
  font-style:  italic;
  font-weight: 400;
  src: url("HelveticaNeue-Italic.woff2") format("woff2"),
       url("HelveticaNeue-Italic.woff") format("woff");
} */

@font-face {
  font-family: 'HelveticaNeue';
  font-style:  normal;
  font-weight: 500;
  src: url("HelveticaNeue-Medium.woff2") format("woff2"),
       url("HelveticaNeue-Medium.woff") format("woff");
}

/* @font-face {
  font-family: 'HelveticaNeue';
  font-style:  italic;
  font-weight: 500;
  src: url("HelveticaNeue-MediumItalic.woff2") format("woff2"),
       url("HelveticaNeue-MediumItalic.woff") format("woff");
} */

/* @font-face {
  font-family: 'HelveticaNeue';
  font-style:  normal;
  font-weight: 600;
  src: url("HelveticaNeue-SemiBold.woff2") format("woff2"),
       url("HelveticaNeue-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'HelveticaNeue';
  font-style:  italic;
  font-weight: 600;
  src: url("HelveticaNeue-SemiBoldItalic.woff2") format("woff2"),
       url("HelveticaNeue-SemiBoldItalic.woff") format("woff");
} */

@font-face {
  font-family: 'HelveticaNeue';
  font-style:  normal;
  font-weight: 700;
  src: url("HelveticaNeue-Bold.woff2") format("woff2"),
       url("HelveticaNeue-Bold.woff") format("woff");
}

/* @font-face {
  font-family: 'HelveticaNeue';
  font-style:  italic;
  font-weight: 700;
  src: url("HelveticaNeue-BoldItalic.woff2") format("woff2"),
       url("HelveticaNeue-BoldItalic.woff") format("woff");
} */

/* @font-face {
  font-family: 'HelveticaNeue';
  font-style:  normal;
  font-weight: 800;
  src: url("HelveticaNeue-ExtraBold.woff2") format("woff2"),
       url("HelveticaNeue-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'HelveticaNeue';
  font-style:  italic;
  font-weight: 800;
  src: url("HelveticaNeue-ExtraBoldItalic.woff2") format("woff2"),
       url("HelveticaNeue-ExtraBoldItalic.woff") format("woff");
} */
/* 
@font-face {
  font-family: 'HelveticaNeue';
  font-style:  normal;
  font-weight: 900;
  src: url("HelveticaNeue-Black.woff2") format("woff2"),
       url("HelveticaNeue-Black.woff") format("woff");
}
@font-face {
  font-family: 'HelveticaNeue';
  font-style:  italic;
  font-weight: 900;
  src: url("HelveticaNeue-BlackItalic.woff2") format("woff2"),
       url("HelveticaNeue-BlackItalic.woff") format("woff");
} */

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'HelveticaNeue', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'HelveticaNeue var', sans-serif; }
  }
*/
/* @font-face {
  font-family: 'HelveticaNeue var';
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("HelveticaNeue-upright.var.woff2") format("woff2");
}
@font-face {
  font-family: 'HelveticaNeue var';
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("HelveticaNeue-italic.var.woff2") format("woff2");
} */


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
/* @font-face {
  font-family: 'HelveticaNeue var experimental';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  src: url("HelveticaNeue.var.woff2") format("woff2");
} */
