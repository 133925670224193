@import "../../styles/config.scss";

.bauwerkContainer {
  .cards {
    .card {
      display: flex;
      background-size: cover;
      position: relative;
      overflow: hidden;
      margin: 10px 10px;

      @media (max-width: $smMax) {
        height: 88px;
        background-position: 50% 50%;
        background-size: 100% auto;
        background-repeat: no-repeat;
      }

      &:before {
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      p {
        color: #fff;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        z-index: 1;
        position: relative;
        margin: auto;
      }

      .checkmark {
        //account size for scaling up
        max-width: 40px;
        max-height: 40px;
        right: 15px;
        top: 15px;
      }
    }

    /*.bridge {
            background: url('../../assets/imgs/bridge.png');
        }*/
    .tunnel {
      background: url("../../assets/imgs/tunnel.png");
    }
    .parken {
      background: url("../../assets/imgs/parken.png");
    }
    .other {
      &::before {
        display: none;
      }
      p {
        color: var(--main);
      }
    }
  }

  form {
    max-width: 730px;
  }
}
