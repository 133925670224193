@import "../../styles/config.scss";

.visualisationContainer {
  .headerWrap {
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  .pdfChartWrapper {
    position: fixed;
    top: -9999px;
    left: -9999px;
    margin: auto;
    z-index: -1;
    background-color: white;
    display: block;
    padding: 0;
    width: 815px;
    height: 560px;

    ul {
      padding: 0;
    }
  }

  .selectList {
    width: 300px;

    &.selectList_expert {
      width: 450px;
    }
  }

  .pill {
    font-weight: 500;
    margin-bottom: 24px;
  }

  .pdfButton {
    margin-bottom: 24px;
    max-width: 11.125rem;
    display: flex;
    align-items: center;
    .button {
      min-height: 3.5rem;
      white-space: nowrap;
    }
    @media (max-width: $smMax) {
      min-width: 100%;
      & .button {
        min-width: 100%;
      }
    }
  }

  .subCards {
    margin-top: 88px;
  }

  .settingsButton {
    margin: 40px 0;
    margin-left: auto;
    margin-right: 10px;
  }

  .card {
    position: relative;
    min-width: auto;
    flex: 1 1 auto;
    margin: 0 auto;
    padding: 24px 32px;
    justify-content: space-evenly;
    height: auto;
    min-height: auto;

    &.mainCard {
      padding: 84px 40px 42px;
      margin-bottom: 40px;

      @media (max-width: $smMax) {
        padding: 8px 20px;
        box-shadow: none;
      }
    }

    &.contact {
      justify-content: space-between;
      @media (max-width: $smMax) {
        p.text-left {
          min-width: 100%;
          margin-bottom: 2rem;
        }
      }
    }

    hr {
      border: 1px solid var(--gray-bg);
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
    }
  }

  .contactUsButton {
    flex: 0 1 auto;
    margin-top: 0;
    width: auto;

    @media (max-width: $smMax) {
      min-width: 150px;
      min-height: 56px;
      line-height: 56px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .flagIcon {
    margin: var(--padding-2);
  }

  .pillsContainer {
    width: 100%;
    .pill {
      margin-right: 0.75rem;
      margin-bottom: 1rem;
    }

    @media (max-width: $smMax) {
      flex-direction: column;
      .pill {
        margin-right: 0;
      }
    }
  }
}
