@import '../../styles/config.scss';

.button {
    //display: inline-block;
    position: relative;
    text-align: center;
    font-weight: 400;
    // width: 100%;ś
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    // overflow: hidden;
    margin-top: 4.5rem;
    transition: background-color .2s linear;
    font-family: HelveticaNeue, sans-serif;
    overflow: visible;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &.bigButton {
        path {
            transition: fill .2s linear;
            fill: var(--main);
        }

        .arrowRightG {
            transition: transform .2s linear;
        }

        &:hover {
            path {
                fill: var(--main-light);
            }

            .arrowRightG {
                transform: translateX(5px);
            }
        }
    }
}

.button:focus,
.button:focus {
    outline: 0;
}

//
// .mask {
//     display: block;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(38,50,56,0.1);
//     // background: rgba(255,255,255, 0.22);
//     transform-origin: 0 100%;
//     transform: scaleY(0);
//     transition: 200ms transform;
// }
/* Mask (Hover, Active) */
// .button:hover .mask,
// .button:hover .mask {
//     transform: scaleY(1);
// }

/* Pill */
.button.bPill {
    color: #fff;
    text-align: center;
    // padding: 14px 0;
    font-size: 20px;
    font-weight: 500;
    // line-height: 17px;
    // height: 56px;
    border-radius: 32px;
    background-color: var(--main);
    border: 2px solid var(--main);

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: var(--button-hover-bg);
        border-color: var(--main);
        // color: var(--main);
        transition: all .2s linear;
    }

    &:focus {
        background-color: var(--button-focus-bg);
    }

    @media (min-width: 897px) {
        &:after {
            top: -7px;
            left: -7px;
            opacity: 0;
            content: "";
            border-width: 2px;
            position: absolute;
            border-style: solid;
            border-radius: 36px;
            width: calc(100% + 14px);
            height: calc(100% + 14px);
            transition: all .2s linear;
            border-color: var(--main-light);
            box-shadow: 0 0 0 6px rgba(16, 68, 106, .1);
        }

        &:focus {
            &:after {
                opacity: 1;
            }
        }
    }

    &:disabled {
        // opacity: 0.5;
        border-color: var(--gray);
        background-color: transparent;
        cursor: not-allowed;
        color: var(--gray);
    }
}

.addButton {
    transition: background-color .2s linear;

    &:hover {
        background-color: var(--main-light);
        border-color: var(--main-hover);
        color: var(--main-hover);
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.button.invertedPill {
    align-self: center;
    // line-height: 22px;
    font-size: 18px;
    border-radius: 32px;
    // padding: 14px 0;
    font-weight: 500;
    padding: 8px 24px;
    color: var(--main);
    border: 2px solid var(--main);
    background-color: transparent;
    transition: all ease-in-out 0.3s;


    &.strokeGrey .toggle {
        stroke: var(--main);
        transition: all ease-in-out 0.3s;
    }

    &:hover:not(:disabled) {
        // box-shadow: inset 0px 0px 0 2px var(--main-light);
        // border-color: var(--main-light) !important;
        // border: 4px solid var(--main);
        color: #fff !important;
        background-color: var(--button-hover-bg);
        transition: all ease-in-out 0.3s;

        .toggle {
            stroke: var(--main-light);
        }
    }

    &:focus:not(:disabled) {
        background-color: var(--button-focus-bg);
        color: #fff;
    }

    &:disabled {
        // opacity: 0.5 !important;
        cursor: not-allowed;
        pointer-events: none;
        border-color: var(--gray) !important;
        color: var(--gray) !important;

        background-color: transparent;
        // filter: grayscale(1);

        &.strokeGrey .toggle {
            stroke: var(--gray);
        }
    }
}

.button.small {
    // width: 200px;
    padding: 0 50px;
}

.button.medium {
    padding: 16px 62px;
    @media (max-width: $smMax) {
        padding: 12px 62px;
    }
}

.button {
    &.fullWidth {
        width: 267px;
        // padding: 14px 32px;
        padding: 20px 32px;

        @media (max-width: 767px) {
            padding: 20px 14px;
        }

    }

    &.fullWidthInverted {
        width: 100%;
        padding: 20px 12px;
        color: var(--main);
        border: 1px solid var(--main);
        background-color: transparent;
        font-size: 1.2rem !important;


        @media (min-width: 370px) {
            font-size: 1.4rem !important;
            padding: 20px 22px;
        }

        @media (min-width: 1200px) {
            padding: 20px 8px;
        }

        @media (min-width: 1270px) {
            padding: 20px 16px;
        }

        @media (min-width: 1370px) {
            padding: 20px 32px;
        }

        &.invertedRed {
            border-color: var(--red-light) !important;
            color: var(--red-light) !important;

            &:before {
                content: " ";
                position: absolute;
                z-index: -1;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                border-radius: 28px;
                border: 1px solid transparent;
                transition: border-color .2s linear;
            }


            &:hover {
                background-color: transparent;

                &:before {
                    // content: " ";
                    // position: absolute;
                    // z-index: -1;
                    // top: 0px;
                    // left: 0px;
                    // right: 0px;
                    // bottom: 0px;
                    // border-radius: 28px;
                    border: 1px solid var(--red-light);
                }
            }
        }

        &:before {
            content: " ";
            position: absolute;
            z-index: -1;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border-radius: 28px;
            border: 1px solid transparent;
            transition: border-color .2s linear;
        }


        &:hover {
            background-color: transparent;
            // border: 2px solid var(--main);

            &:before {
                // content: " ";
                // position: absolute;
                // z-index: -1;
                // top: 0px;
                // left: 0px;
                // right: 0px;
                // bottom: 0px;
                // border-radius: 28px;
                border: 1px solid var(--main);
            }
        }
    }
}

/* Link */
.basicLink {
    color: var(--main);
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight: 500;
    // text-transform: uppercase;
    transition: color linear 0.2s;
    padding: 2px;

    &.preventFocus {
        border-color: transparent;

        &:after {
            opacity: 0 !important;
        }
    }

    &:after {
        border-color: var(--main);
        bottom: -2px;
        left: 0;
        content: "";
        border-width: 1px;
        position: absolute;
        border-style: solid;
        width: 100%;
        // height: 2px;
    }


    &:hover {
        color: var(--button-hover-bg);
    }

    &:focus {
        color: var(--button-focus-bg);
    }
}

button {
    transition: color linear 0.2s;
}
